// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-front-tsx": () => import("./../../../src/templates/front.tsx" /* webpackChunkName: "component---src-templates-front-tsx" */),
  "component---src-templates-single-tsx": () => import("./../../../src/templates/single.tsx" /* webpackChunkName: "component---src-templates-single-tsx" */)
}

